import { Remediator } from './Remediator';
// Base class - DO NOT expose static remediationName
export class VerifyAuthenticator extends Remediator {
    constructor() {
        super(...arguments);
        this.map = {
            'credentials': []
        };
    }
    canRemediate() {
        const challengeType = this.getRelatesToType();
        if (this.values.verificationCode
            && ['email', 'phone'].includes(challengeType)) {
            return true;
        }
        if (this.values.password && challengeType === 'password') {
            return true;
        }
        return false;
    }
    mapCredentials() {
        return {
            passcode: this.values.verificationCode || this.values.password
        };
    }
    getInputCredentials(input) {
        const challengeType = this.getRelatesToType();
        const name = challengeType === 'password' ? 'password' : 'verificationCode';
        return Object.assign(Object.assign({}, input.form.value[0]), { name, type: 'string', required: input.required });
    }
}
VerifyAuthenticator.remediationName = 'challenge-authenticator';
