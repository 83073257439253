import { Remediator } from './Remediator';
// Base class - DO NOT expose static remediationName
export class AuthenticatorData extends Remediator {
    constructor(remediation, values = {}) {
        var _a;
        super(remediation, values);
        this.map = {
            'authenticator': []
        };
        // Unify authenticator input type
        const { authenticators } = this.values;
        const authenticatorType = this.getRelatesToType();
        const authenticator = (_a = authenticators) === null || _a === void 0 ? void 0 : _a.find(authenticator => authenticator.type === authenticatorType);
        if (authenticator) {
            // map
            this.values.authenticators = authenticators.map(authenticator => {
                if (authenticatorType === authenticator.type) {
                    return this.mapAuthenticatorFromValues(authenticator);
                }
                return authenticator;
            });
        }
        else {
            // add
            this.values.authenticators = [
                ...authenticators,
                this.mapAuthenticatorFromValues()
            ];
        }
    }
    getNextStep() {
        const common = super.getNextStep();
        const options = this.getMethodTypes();
        return Object.assign(Object.assign({}, common), (options && { options }));
    }
    // Grab authenticator from authenticators list
    getAuthenticatorFromValues() {
        if (!this.values.authenticators) {
            return null;
        }
        const authenticatorType = this.getRelatesToType();
        const authenticator = this.values.authenticators
            .find(authenticator => authenticator.type === authenticatorType);
        return authenticator;
    }
    mapAuthenticatorFromValues(authenticator) {
        // add methodType to authenticator if it exists in values
        const type = this.getRelatesToType();
        const { methodType } = this.values;
        return Object.assign(Object.assign({ type }, (authenticator && authenticator)), (methodType && { methodType }));
    }
    getAuthenticatorFromRemediation() {
        const authenticator = this.remediation.value
            .find(({ name }) => name === 'authenticator');
        return authenticator;
    }
    getMethodTypes() {
        var _a;
        const authenticator = this.getAuthenticatorFromRemediation();
        return (_a = authenticator.form.value.find(({ name }) => name === 'methodType')) === null || _a === void 0 ? void 0 : _a.options;
    }
}
