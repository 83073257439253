export function getAllValues(idxRemediation) {
    return idxRemediation.value.map(r => r.name);
}
export function getRequiredValues(idxRemediation) {
    return idxRemediation.value.reduce((required, cur) => {
        if (cur.required) {
            required.push(cur.name);
        }
        return required;
    }, []);
}
export function titleCase(str) {
    return str.charAt(0).toUpperCase() + str.substring(1);
}
export function getAuthenticatorFromRemediation(remediation) {
    return remediation.value.find(({ name }) => name === 'authenticator');
}
