export var IdxStatus;
(function (IdxStatus) {
    IdxStatus[IdxStatus["SUCCESS"] = 0] = "SUCCESS";
    IdxStatus[IdxStatus["PENDING"] = 1] = "PENDING";
    IdxStatus[IdxStatus["FAILURE"] = 2] = "FAILURE";
    IdxStatus[IdxStatus["TERMINAL"] = 3] = "TERMINAL";
    IdxStatus[IdxStatus["CANCELED"] = 4] = "CANCELED";
})(IdxStatus || (IdxStatus = {}));
export var IdxFeature;
(function (IdxFeature) {
    IdxFeature[IdxFeature["PASSWORD_RECOVERY"] = 0] = "PASSWORD_RECOVERY";
    IdxFeature[IdxFeature["REGISTRATION"] = 1] = "REGISTRATION";
    IdxFeature[IdxFeature["SOCIAL_IDP"] = 2] = "SOCIAL_IDP";
})(IdxFeature || (IdxFeature = {}));
