var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AuthSdkError } from './errors';
import { getConsole } from './util';
import { EVENT_ADDED, EVENT_REMOVED } from './TokenManager';
const PCancelable = require('p-cancelable');
export const INITIAL_AUTH_STATE = null;
const DEFAULT_PENDING = {
    updateAuthStatePromise: null,
    canceledTimes: 0
};
const EVENT_AUTH_STATE_CHANGE = 'authStateChange';
const MAX_PROMISE_CANCEL_TIMES = 10;
// only compare first level of authState
const isSameAuthState = (prevState, state) => {
    // initial state is null
    if (!prevState) {
        return false;
    }
    return prevState.isAuthenticated === state.isAuthenticated
        && JSON.stringify(prevState.idToken) === JSON.stringify(state.idToken)
        && JSON.stringify(prevState.accessToken) === JSON.stringify(state.accessToken)
        && prevState.error === state.error;
};
export class AuthStateManager {
    constructor(sdk) {
        if (!sdk.emitter) {
            throw new AuthSdkError('Emitter should be initialized before AuthStateManager');
        }
        this._sdk = sdk;
        this._pending = Object.assign({}, DEFAULT_PENDING);
        this._authState = INITIAL_AUTH_STATE;
        this._logOptions = {};
        // Listen on tokenManager events to start updateState process
        // "added" event is emitted in both add and renew process
        // Only listen on "added" event to update auth state
        sdk.tokenManager.on(EVENT_ADDED, (key, token) => {
            this._setLogOptions({ event: EVENT_ADDED, key, token });
            this.updateAuthState();
        });
        sdk.tokenManager.on(EVENT_REMOVED, (key, token) => {
            this._setLogOptions({ event: EVENT_REMOVED, key, token });
            this.updateAuthState();
        });
    }
    _setLogOptions(options) {
        this._logOptions = options;
    }
    getAuthState() {
        return this._authState;
    }
    updateAuthState() {
        return __awaiter(this, void 0, void 0, function* () {
            const { transformAuthState, devMode } = this._sdk.options;
            const log = (status) => {
                const { event, key, token } = this._logOptions;
                getConsole().group(`OKTA-AUTH-JS:updateAuthState: Event:${event} Status:${status}`);
                getConsole().log(key, token);
                getConsole().log('Current authState', this._authState);
                getConsole().groupEnd();
                // clear log options after logging
                this._logOptions = {};
            };
            const emitAuthStateChange = (authState) => {
                if (isSameAuthState(this._authState, authState)) {
                    devMode && log('unchanged');
                    return;
                }
                this._authState = authState;
                // emit new authState object
                this._sdk.emitter.emit(EVENT_AUTH_STATE_CHANGE, Object.assign({}, authState));
                devMode && log('emitted');
            };
            const finalPromise = (origPromise) => {
                return this._pending.updateAuthStatePromise.then(() => {
                    const curPromise = this._pending.updateAuthStatePromise;
                    if (curPromise && curPromise !== origPromise) {
                        return finalPromise(curPromise);
                    }
                    return this.getAuthState();
                });
            };
            if (this._pending.updateAuthStatePromise) {
                if (this._pending.canceledTimes >= MAX_PROMISE_CANCEL_TIMES) {
                    // stop canceling then starting a new promise
                    // let existing promise finish to prevent running into loops
                    devMode && log('terminated');
                    return finalPromise(this._pending.updateAuthStatePromise);
                }
                else {
                    this._pending.updateAuthStatePromise.cancel();
                }
            }
            /* eslint-disable complexity */
            const cancelablePromise = new PCancelable((resolve, _, onCancel) => {
                onCancel.shouldReject = false;
                onCancel(() => {
                    this._pending.updateAuthStatePromise = null;
                    this._pending.canceledTimes = this._pending.canceledTimes + 1;
                    devMode && log('canceled');
                });
                const emitAndResolve = (authState) => {
                    if (cancelablePromise.isCanceled) {
                        resolve();
                        return;
                    }
                    // emit event and resolve promise 
                    emitAuthStateChange(authState);
                    resolve();
                    // clear pending states after resolve
                    this._pending = Object.assign({}, DEFAULT_PENDING);
                };
                this._sdk.isAuthenticated()
                    .then(isAuthenticated => {
                    if (cancelablePromise.isCanceled) {
                        resolve();
                        return;
                    }
                    const { accessToken, idToken, refreshToken } = this._sdk.tokenManager.getTokensSync();
                    const authState = {
                        accessToken,
                        idToken,
                        refreshToken,
                        isAuthenticated
                    };
                    const promise = transformAuthState
                        ? transformAuthState(this._sdk, authState)
                        : Promise.resolve(authState);
                    promise
                        .then(authState => emitAndResolve(authState))
                        .catch(error => emitAndResolve({
                        accessToken,
                        idToken,
                        refreshToken,
                        isAuthenticated: false,
                        error
                    }));
                });
            });
            /* eslint-enable complexity */
            this._pending.updateAuthStatePromise = cancelablePromise;
            return finalPromise(cancelablePromise);
        });
    }
    subscribe(handler) {
        this._sdk.emitter.on(EVENT_AUTH_STATE_CHANGE, handler);
    }
    unsubscribe(handler) {
        this._sdk.emitter.off(EVENT_AUTH_STATE_CHANGE, handler);
    }
}
