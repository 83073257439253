import { Remediator } from './Remediator';
import { getAuthenticatorFromRemediation } from '../util';
// Find matched authenticator in provided order
function findMatchedOption(authenticators, options) {
    let option;
    for (let authenticator of authenticators) {
        option = options
            .find(({ relatesTo }) => relatesTo.type === authenticator.type);
        if (option) {
            break;
        }
    }
    return option;
}
// Base class - DO NOT expose static remediationName
export class SelectAuthenticator extends Remediator {
    constructor(remediation, values = {}) {
        super(remediation, values);
        this.map = {
            authenticator: []
        };
        // Unify authenticator input type
        const { authenticator: selectedAuthenticator, authenticators } = this.values;
        const hasSelectedAuthenticatorInList = authenticators === null || authenticators === void 0 ? void 0 : authenticators.some((authenticator => authenticator.type === selectedAuthenticator));
        if (selectedAuthenticator && !hasSelectedAuthenticatorInList) {
            // add selected authenticator to list
            this.values.authenticators = [
                ...(authenticators || []),
                { type: selectedAuthenticator }
            ];
        }
    }
    canRemediate() {
        const { authenticators } = this.values;
        const authenticatorFromRemediation = getAuthenticatorFromRemediation(this.remediation);
        const { options } = authenticatorFromRemediation;
        // Let users select authenticator if no input is provided
        if (!authenticators || !authenticators.length) {
            return false;
        }
        // Proceed with provided authenticators
        const matchedOption = findMatchedOption(authenticators, options);
        if (matchedOption) {
            return true;
        }
        return false;
    }
    getNextStep() {
        const common = super.getNextStep();
        const authenticatorFromRemediation = getAuthenticatorFromRemediation(this.remediation);
        const options = authenticatorFromRemediation.options.map(option => {
            const { label, relatesTo: { type } } = option;
            return { label, value: type };
        });
        return Object.assign(Object.assign({}, common), { options });
    }
    mapAuthenticator(remediationValue) {
        const { authenticators } = this.values;
        const { options } = remediationValue;
        const selectedOption = findMatchedOption(authenticators, options);
        return {
            id: selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.value.form.value.find(({ name }) => name === 'id').value
        };
    }
    getInputAuthenticator() {
        return { name: 'authenticator', type: 'string' };
    }
}
