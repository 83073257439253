import { Remediator } from './Base/Remediator';
export class Identify extends Remediator {
    constructor(remediation, values) {
        super(remediation, values);
        this.map = {
            'identifier': ['username'],
            'credentials': []
        };
        // add password authenticator to authenticators list if password is provided
        const { password, authenticators } = this.values;
        if (password && !authenticators.some(authenticator => authenticator.type === 'password')) {
            this.values = Object.assign(Object.assign({}, this.values), { authenticators: [{ type: 'password' }, ...authenticators] });
        }
    }
    canRemediate() {
        const { identifier } = this.getData();
        return !!identifier;
    }
    mapCredentials() {
        return { passcode: this.values.password };
    }
    getInputCredentials(input) {
        return Object.assign(Object.assign({}, input.form.value[0]), { name: 'password', required: input.required });
    }
    getValuesAfterProceed() {
        var _a;
        // Handle username + password scenario
        // remove "password" from authenticator array when remediation is finished
        if (this.remediation.value.some(({ name }) => name === 'credentials')) {
            const authenticators = (_a = this.values.authenticators) === null || _a === void 0 ? void 0 : _a.filter(authenticator => authenticator.type !== 'password');
            return Object.assign(Object.assign({}, this.values), { authenticators });
        }
        return super.getValuesAfterProceed();
    }
}
Identify.remediationName = 'identify';
