import { PKCE_STORAGE_NAME, TOKEN_STORAGE_NAME, TRANSACTION_STORAGE_NAME, IDX_RESPONSE_STORAGE_NAME, CACHE_STORAGE_NAME, REDIRECT_OAUTH_PARAMS_NAME } from './constants';
import SavedObject from './SavedObject';
import { isBrowser } from './features';
import { warn } from './util';
import { AuthSdkError } from './errors';
function logServerSideMemoryStorageWarning(options) {
    if (!isBrowser() && !options.storageProvider && !options.storageProvider) {
        // eslint-disable-next-line max-len
        warn('Memory storage can only support simple single user use case on server side, please provide custom storageProvider or storageKey if advanced scenarios need to be supported.');
    }
}
export default class StorageManager {
    constructor(storageManagerOptions, cookieOptions, storageUtil) {
        this.storageManagerOptions = storageManagerOptions;
        this.cookieOptions = cookieOptions;
        this.storageUtil = storageUtil;
    }
    // combines defaults in order
    getOptionsForSection(sectionName, overrideOptions) {
        return Object.assign({}, this.storageManagerOptions[sectionName], overrideOptions);
    }
    // generic method to get any available storage provider
    getStorage(options) {
        options = Object.assign({}, this.cookieOptions, options); // set defaults
        if (options.storageProvider) {
            return options.storageProvider;
        }
        let { storageType, storageTypes } = options;
        if (storageType === 'sessionStorage') {
            options.sessionCookie = true;
        }
        // Maintain compatibility. Automatically fallback. May change in next major version. OKTA-362589
        if (storageType && storageTypes) {
            const idx = storageTypes.indexOf(storageType);
            if (idx >= 0) {
                storageTypes = storageTypes.slice(idx);
                storageType = null;
            }
        }
        if (!storageType) {
            storageType = this.storageUtil.findStorageType(storageTypes);
        }
        return this.storageUtil.getStorageByType(storageType, options);
    }
    // stateToken, interactionHandle
    getTransactionStorage(options) {
        options = this.getOptionsForSection('transaction', options);
        logServerSideMemoryStorageWarning(options);
        const storage = this.getStorage(options);
        const storageKey = options.storageKey || TRANSACTION_STORAGE_NAME;
        return new SavedObject(storage, storageKey);
    }
    // intermediate idxResponse
    // store for network traffic optimazation purpose
    // TODO: revisit in auth-js 6.0 epic JIRA: OKTA-399791
    getIdxResponseStorage(options) {
        let storage;
        if (isBrowser()) {
            // on browser side only use memory storage 
            try {
                storage = this.storageUtil.getStorageByType('memory', options);
            }
            catch (e) {
                // it's ok to miss response storage
                // eslint-disable-next-line max-len
                warn('No response storage found, you may want to provide custom implementation for intermediate idx responses to optimize the network traffic');
            }
        }
        else {
            // on server side re-use transaction custom storage
            const transactionStorage = this.getTransactionStorage(options);
            if (transactionStorage) {
                storage = {
                    getItem: (key) => {
                        const transaction = transactionStorage.getStorage();
                        if (transaction && transaction[key]) {
                            return transaction[key];
                        }
                        return null;
                    },
                    setItem: (key, val) => {
                        const transaction = transactionStorage.getStorage();
                        if (!transaction) {
                            throw new AuthSdkError('Transaction has been cleared, failed to save idxState');
                        }
                        transaction[key] = val;
                        transactionStorage.setStorage(transaction);
                    },
                    removeItem: (key) => {
                        const transaction = transactionStorage.getStorage();
                        if (!transaction) {
                            return;
                        }
                        delete transaction[key];
                        transactionStorage.setStorage(transaction);
                    }
                };
            }
        }
        if (!storage) {
            return null;
        }
        return new SavedObject(storage, IDX_RESPONSE_STORAGE_NAME);
    }
    // access_token, id_token, refresh_token
    getTokenStorage(options) {
        options = this.getOptionsForSection('token', options);
        logServerSideMemoryStorageWarning(options);
        const storage = this.getStorage(options);
        const storageKey = options.storageKey || TOKEN_STORAGE_NAME;
        return new SavedObject(storage, storageKey);
    }
    // caches well-known response, among others
    getHttpCache(options) {
        options = this.getOptionsForSection('cache', options);
        const storage = this.getStorage(options);
        const storageKey = options.storageKey || CACHE_STORAGE_NAME;
        return new SavedObject(storage, storageKey);
    }
    // Will be removed in an upcoming major version. OKTA-362589
    getLegacyPKCEStorage(options) {
        options = this.getOptionsForSection('legacy-pkce', options);
        const storage = this.getStorage(options);
        const storageKey = options.storageKey || PKCE_STORAGE_NAME;
        return new SavedObject(storage, storageKey);
    }
    getLegacyOAuthParamsStorage(options) {
        options = this.getOptionsForSection('legacy-oauth-params', options);
        const storage = this.getStorage(options);
        const storageKey = options.storageKey || REDIRECT_OAUTH_PARAMS_NAME;
        return new SavedObject(storage, storageKey);
    }
}
