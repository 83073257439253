import { Remediator } from './Base/Remediator';
export class EnrollProfile extends Remediator {
    constructor() {
        super(...arguments);
        this.map = {
            'userProfile': []
        };
    }
    mapUserProfile() {
        const { firstName, lastName, email } = this.values;
        return {
            firstName,
            lastName,
            email,
        };
    }
    getInputUserProfile(input) {
        return [...input.form.value];
    }
    getErrorMessages(errorRemediation) {
        return errorRemediation.value[0].form.value.reduce((errors, field) => {
            if (field.messages) {
                errors.push(field.messages.value[0].message);
            }
            return errors;
        }, []);
    }
}
EnrollProfile.remediationName = 'enroll-profile';
