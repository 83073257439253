export class FlowMonitor {
    shouldBreak(remediator) {
        if (!this.previousRemediator) {
            this.previousRemediator = remediator;
            return false;
        }
        if (this.previousRemediator.getName() === remediator.getName()) {
            return true;
        }
        this.previousRemediator = remediator;
        return false;
    }
    isRemediatorCandidate(
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    remediator, remediations, values) {
        const remediatorName = remediator.getName();
        if (!values.skip && remediatorName === 'skip') {
            return false;
        }
        if (values.skip && remediatorName !== 'skip') {
            return false;
        }
        return true;
    }
}
