import { Remediator } from './Base/Remediator';
export class RedirectIdp extends Remediator {
    canRemediate() {
        return false;
    }
    getNextStep() {
        const { name, type, idp, href } = this.remediation;
        return {
            name,
            type,
            idp,
            href
        };
    }
}
RedirectIdp.remediationName = 'redirect-idp';
