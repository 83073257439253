var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable max-statements, max-depth, complexity */
import idx from '@okta/okta-idx-js';
import { AuthSdkError } from '../errors';
import { isRawIdxResponse, } from './types/idx-js';
// Return first match idxRemediation in allowed remediators
export function getRemediator(idxRemediations, values, options) {
    const { flow, flowMonitor } = options;
    let remediator;
    const remediatorCandidates = [];
    for (let remediation of idxRemediations) {
        const isRemeditionInFlow = Object.keys(flow).includes(remediation.name);
        if (!isRemeditionInFlow) {
            continue;
        }
        const T = flow[remediation.name];
        remediator = new T(remediation, values);
        if (flowMonitor.isRemediatorCandidate(remediator, idxRemediations, values)) {
            if (remediator.canRemediate()) {
                // found the remediator
                return remediator;
            }
            // remediator cannot handle the current values
            // maybe return for next step
            remediatorCandidates.push(remediator);
        }
    }
    // TODO: why is it a problem to have multiple remediations? 
    // JIRA: https://oktainc.atlassian.net/browse/OKTA-400758
    // if (remediatorCandidates.length > 1) {
    //   const remediationNames = remediatorCandidates.reduce((acc, curr) => {
    //     const name = curr.getName();
    //     return acc ? `${acc}, ${name}` : name;
    //   }, '');
    //   throw new AuthSdkError(`
    //     More than one remediation can match the current input, remediations: ${remediationNames}
    //   `);
    // }
    return remediatorCandidates[0];
}
function isTerminalResponse(idxResponse) {
    const { neededToProceed, interactionCode } = idxResponse;
    return !neededToProceed.length && !interactionCode;
}
function canSkipFn(idxResponse) {
    return idxResponse.neededToProceed.some(({ name }) => name === 'skip');
}
function getIdxMessages(idxResponse, flow) {
    var _a;
    let messages = [];
    const { rawIdxState, neededToProceed } = idxResponse;
    // Handle global messages
    const globalMessages = (_a = rawIdxState.messages) === null || _a === void 0 ? void 0 : _a.value.map(message => message);
    if (globalMessages) {
        messages = [...messages, ...globalMessages];
    }
    // Handle field messages for current flow
    for (let remediation of neededToProceed) {
        const T = flow[remediation.name];
        if (!T) {
            continue;
        }
        const remediator = new T(remediation);
        const fieldMessages = remediator.getMessages();
        if (fieldMessages) {
            messages = [...messages, ...fieldMessages];
        }
    }
    return messages;
}
function getNextStep(remediator, idxResponse) {
    const nextStep = remediator.getNextStep();
    const canSkip = canSkipFn(idxResponse);
    return Object.assign(Object.assign({}, nextStep), { canSkip });
}
function handleIdxError(e, flow, remediator) {
    // Handle idx messages
    if (isRawIdxResponse(e)) {
        const idxState = idx.makeIdxState(e);
        const terminal = isTerminalResponse(idxState);
        const messages = getIdxMessages(idxState, flow);
        if (terminal) {
            return { terminal, messages };
        }
        else {
            const nextStep = remediator && getNextStep(remediator, idxState);
            return Object.assign({ messages }, (nextStep && { nextStep }));
        }
    }
    // Thrown error terminates the interaction with idx
    throw e;
}
// This function is called recursively until it reaches success or cannot be remediated
export function remediate(idxResponse, values, options) {
    return __awaiter(this, void 0, void 0, function* () {
        const { neededToProceed } = idxResponse;
        const { actions, flow, flowMonitor } = options;
        // Try actions in idxResponse first
        if (actions) {
            for (let action of actions) {
                if (typeof idxResponse.actions[action] === 'function') {
                    try {
                        idxResponse = yield idxResponse.actions[action]();
                    }
                    catch (e) {
                        return handleIdxError(e, flow);
                    }
                    if (action === 'cancel') {
                        return { canceled: true };
                    }
                    return remediate(idxResponse, values, options); // recursive call
                }
            }
        }
        const remediator = getRemediator(neededToProceed, values, options);
        if (!remediator) {
            throw new AuthSdkError(`
      No remediation can match current flow, check policy settings in your org.
      Remediations: [${neededToProceed.reduce((acc, curr) => acc ? acc + ' ,' + curr.name : curr.name, '')}]
    `);
        }
        if (flowMonitor.shouldBreak(remediator)) {
            throw new AuthSdkError(`
      Remediation run into loop, break!!! remediation: ${remediator.getName()}
    `);
        }
        // Recursive loop breaker
        // Return next step to the caller
        if (!remediator.canRemediate()) {
            const nextStep = getNextStep(remediator, idxResponse);
            return { idxResponse, nextStep };
        }
        const name = remediator.getName();
        const data = remediator.getData();
        try {
            idxResponse = yield idxResponse.proceed(name, data);
            // Successfully get interaction code
            if (idxResponse.interactionCode) {
                return { idxResponse };
            }
            // Reach to terminal state
            const terminal = isTerminalResponse(idxResponse);
            const messages = getIdxMessages(idxResponse, flow);
            if (terminal) {
                return { terminal, messages };
            }
            // Handle idx message in nextStep
            if (messages.length) {
                const nextStep = getNextStep(remediator, idxResponse);
                return { nextStep, messages };
            }
            // We may want to trim the values bag for the next remediation
            // Let the remediator decide what the values should be (default to current values)
            values = remediator.getValuesAfterProceed();
            return remediate(idxResponse, values, options); // recursive call
        }
        catch (e) {
            return handleIdxError(e, flow, remediator);
        }
    });
}
