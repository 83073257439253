/* eslint-disable complexity */
import { AuthSdkError } from '../../../errors';
import { getAllValues, getRequiredValues, titleCase } from '../util';
// Base class - DO NOT expose static remediationName
export class Remediator {
    constructor(remediation, values = {}) {
        var _a;
        // map authenticators to Authenticator[] type
        values.authenticators = (((_a = values.authenticators) === null || _a === void 0 ? void 0 : _a.map(authenticator => {
            return typeof authenticator === 'string'
                ? { type: authenticator } : authenticator;
        })) || []);
        // assign fields to the instance
        this.values = values;
        this.remediation = remediation;
    }
    getName() {
        return this.remediation.name;
    }
    // Override this method to provide custom check
    canRemediate() {
        if (!this.map) {
            return false;
        }
        const required = getRequiredValues(this.remediation);
        const needed = required.find((key) => !this.hasData(key));
        if (needed) {
            return false; // missing data for a required field
        }
        return true; // all required fields have available data
    }
    // returns an object for the entire remediation form, or just a part
    getData(key) {
        if (!key) {
            let allValues = getAllValues(this.remediation);
            let res = allValues.reduce((data, key) => {
                data[key] = this.getData(key); // recursive
                return data;
            }, {});
            return res;
        }
        // Map value by "map${Property}" function in each subClass
        if (typeof this[`map${titleCase(key)}`] === 'function') {
            return this[`map${titleCase(key)}`](this.remediation.value.find(({ name }) => name === key));
        }
        if (!this.map) {
            return this.values[key];
        }
        // Handle general primitive types
        const entry = this.map[key];
        if (!entry) {
            return this.values[key];
        }
        // find the first aliased property that returns a truthy value
        for (let i = 0; i < entry.length; i++) {
            let val = this.values[entry[i]];
            if (val) {
                return val;
            }
        }
    }
    hasData(key // idx name
    ) {
        // no attempt to format, we want simple true/false
        // First see if the remediation has a mapping for this vale
        const data = this.getData(key);
        if (typeof data === 'object') {
            return !!Object.keys(data).find(key => !!data[key]);
        }
        return !!data;
    }
    getNextStep() {
        const name = this.getName();
        const type = this.getRelatesToType();
        const inputs = this.getInputs();
        return Object.assign({ name, inputs }, (type && { type }));
    }
    // Get inputs for the next step
    getInputs() {
        if (!this.map) {
            return [];
        }
        return Object.keys(this.map).reduce((inputs, key) => {
            const inputFromRemediation = this.remediation.value.find(item => item.name === key);
            if (!inputFromRemediation) {
                return inputs;
            }
            let input;
            const aliases = this.map[key];
            const { type } = inputFromRemediation;
            if (typeof this[`getInput${titleCase(key)}`] === 'function') {
                input = this[`getInput${titleCase(key)}`](inputFromRemediation);
            }
            else if (type !== 'object') {
                // handle general primitive types
                let name;
                if (aliases.length === 1) {
                    name = aliases[0];
                }
                else {
                    // try find key from values
                    name = aliases.find(name => Object.keys(this.values).includes(name));
                }
                if (name) {
                    input = Object.assign(Object.assign({}, inputFromRemediation), { name });
                }
            }
            if (!input) {
                throw new AuthSdkError(`Missing custom getInput${titleCase(key)} method in Remediator: ${this.getName()}`);
            }
            if (Array.isArray(input)) {
                input.forEach(i => inputs.push(i));
            }
            else {
                inputs.push(input);
            }
            return inputs;
        }, []);
    }
    // Override this method to grab messages per remediation
    getMessages() {
        var _a, _b;
        if (!this.remediation.value) {
            return;
        }
        return (_b = (_a = this.remediation.value[0]) === null || _a === void 0 ? void 0 : _a.form) === null || _b === void 0 ? void 0 : _b.value.reduce((messages, field) => {
            if (field.messages) {
                messages = [...messages, ...field.messages.value];
            }
            return messages;
        }, []);
    }
    // Prepare values for the next remediation
    // In general, remove finished authenticator from list
    getValuesAfterProceed() {
        var _a;
        const authenticatorType = this.getRelatesToType();
        const authenticators = (_a = this.values.authenticators) === null || _a === void 0 ? void 0 : _a.filter(authenticator => authenticator.type !== authenticatorType);
        return Object.assign(Object.assign({}, this.values), { authenticators });
    }
    getRelatesToType() {
        var _a;
        return (_a = this.remediation.relatesTo) === null || _a === void 0 ? void 0 : _a.value.type;
    }
}
