var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable max-statements, complexity */
import { interact } from './interact';
import { introspect } from './introspect';
import { remediate } from './remediate';
import * as remediators from './remediators';
import { IdxStatus, IdxFeature, } from '../types';
function getEnabledFeatures(idxResponse) {
    const res = [];
    const { actions, neededToProceed } = idxResponse;
    if (actions['currentAuthenticator-recover']) {
        res.push(IdxFeature.PASSWORD_RECOVERY);
    }
    if (neededToProceed.some(({ name }) => name === 'select-enroll-profile')) {
        res.push(IdxFeature.REGISTRATION);
    }
    if (neededToProceed.some(({ name }) => name === 'redirect-idp')) {
        res.push(IdxFeature.SOCIAL_IDP);
    }
    return res;
}
function getAvailableSteps(remediations) {
    const res = [];
    const remediatorMap = Object.values(remediators).reduce((map, remediatorClass) => {
        // Only add concrete subclasses to the map
        if (remediatorClass.remediationName) {
            map[remediatorClass.remediationName] = remediatorClass;
        }
        return map;
    }, {});
    for (let remediation of remediations) {
        const T = remediatorMap[remediation.name];
        if (T) {
            const remediator = new T(remediation);
            res.push(remediator.getNextStep());
        }
    }
    return res;
}
export function run(authClient, options) {
    return __awaiter(this, void 0, void 0, function* () {
        let tokens;
        let nextStep;
        let messages;
        let error;
        let meta;
        let enabledFeatures;
        let availableSteps;
        let status = IdxStatus.PENDING;
        let shouldClearTransaction = false;
        try {
            // Start/resume the flow
            const { interactionHandle, meta: metaFromResp } = yield interact(authClient, options);
            // Introspect to get idx response
            const idxResponse = yield introspect(authClient, { interactionHandle });
            if (!options.flow && !options.actions) {
                // handle start transaction
                meta = metaFromResp;
                enabledFeatures = getEnabledFeatures(idxResponse);
                availableSteps = getAvailableSteps(idxResponse.neededToProceed);
            }
            else {
                const values = Object.assign(Object.assign({}, options), { stateHandle: idxResponse.rawIdxState.stateHandle });
                // Can we handle the remediations?
                const { idxResponse: idxResponseFromResp, nextStep: nextStepFromResp, terminal, canceled, messages: messagesFromResp, } = yield remediate(idxResponse, values, options);
                // Track fields from remediation response
                nextStep = nextStepFromResp;
                messages = messagesFromResp;
                // Save intermediate idx response in storage to reduce introspect call
                if (nextStep && idxResponseFromResp) {
                    authClient.transactionManager.saveIdxResponse(idxResponseFromResp.rawIdxState);
                }
                if (terminal) {
                    status = IdxStatus.TERMINAL;
                    shouldClearTransaction = true;
                }
                if (canceled) {
                    status = IdxStatus.CANCELED;
                    shouldClearTransaction = true;
                }
                else if (idxResponseFromResp === null || idxResponseFromResp === void 0 ? void 0 : idxResponseFromResp.interactionCode) {
                    const { clientId, codeVerifier, ignoreSignature, redirectUri, urls, scopes, } = metaFromResp;
                    tokens = yield authClient.token.exchangeCodeForTokens({
                        interactionCode: idxResponseFromResp.interactionCode,
                        clientId,
                        codeVerifier,
                        ignoreSignature,
                        redirectUri,
                        scopes
                    }, urls);
                    status = IdxStatus.SUCCESS;
                    shouldClearTransaction = true;
                }
            }
        }
        catch (err) {
            error = err;
            status = IdxStatus.FAILURE;
            shouldClearTransaction = true;
        }
        if (shouldClearTransaction) {
            authClient.transactionManager.clear();
        }
        return Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({ status }, (meta && { meta })), (enabledFeatures && { enabledFeatures })), (availableSteps && { availableSteps })), (tokens && { tokens: tokens.tokens })), (nextStep && { nextStep })), (messages && { messages })), (error && { error }));
    });
}
