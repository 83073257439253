var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { run } from './run';
import { transactionMetaExist } from './transactionMeta';
import { startTransaction } from './startTransaction';
import { SelectEnrollProfile, EnrollProfile, SelectAuthenticatorEnroll, EnrollAuthenticator, AuthenticatorEnrollmentData, Skip, } from './remediators';
import { RegistrationFlowMonitor } from './flowMonitors';
import { AuthSdkError } from '../errors';
import { IdxFeature, IdxStatus, } from '../types';
const flow = {
    'select-enroll-profile': SelectEnrollProfile,
    'enroll-profile': EnrollProfile,
    'authenticator-enrollment-data': AuthenticatorEnrollmentData,
    'select-authenticator-enroll': SelectAuthenticatorEnroll,
    'enroll-authenticator': EnrollAuthenticator,
    'skip': Skip,
};
export function register(authClient, options) {
    return __awaiter(this, void 0, void 0, function* () {
        // Only check at the beginning of the transaction
        if (!transactionMetaExist(authClient)) {
            const { enabledFeatures } = yield startTransaction(authClient, options);
            if (enabledFeatures && !enabledFeatures.includes(IdxFeature.REGISTRATION)) {
                const error = new AuthSdkError('Registration is not supported based on your current org configuration.');
                return { status: IdxStatus.FAILURE, error };
            }
        }
        const flowMonitor = new RegistrationFlowMonitor();
        return run(authClient, Object.assign(Object.assign({}, options), { flow,
            flowMonitor }));
    });
}
