import { Remediator } from './Base/Remediator';
export class ReEnrollAuthenticator extends Remediator {
    constructor() {
        super(...arguments);
        this.map = {
            'credentials': []
        };
    }
    mapCredentials() {
        return {
            passcode: this.values.newPassword,
        };
    }
    getInputCredentials(input) {
        const challengeType = this.getRelatesToType();
        const name = challengeType === 'password' ? 'newPassword' : 'verificationCode';
        return Object.assign(Object.assign({}, input.form.value[0]), { name });
    }
}
ReEnrollAuthenticator.remediationName = 'reenroll-authenticator';
